<script setup lang="ts">
import { reactive } from 'vue'
import dayjs from 'dayjs'

// const settingStore = useSettingStore()

export interface DateDataType {
  dateDay: string
  dateYear: string
  dateWeek: string
  timing: NodeJS.Timer | null
}

const dateData = reactive<DateDataType>({
  dateDay: '',
  dateYear: '',
  dateWeek: '',
  timing: null,
})

// const { setSettingShow } = useSettingStore()
const weekday = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
function timeFn() {
  dateData.timing = setInterval(() => {
    dateData.dateDay = dayjs().format('YYYY-MM-DD hh : mm : ss')
    dateData.dateWeek = weekday[dayjs().day()]!
  }, 1000)
}
timeFn()
</script>

<template>
  <div class="d-flex jc-center title_wrap">
    <!-- <div class="zuojuxing" />
    <div class="youjuxing" />
    <div class="guang" />
    <div class="d-flex jc-center">
      <div class="title" />
    </div>
    <div class="timers">
      {{ dateData.dateYear }} {{ dateData.dateWeek }} {{ dateData.dateDay }}

      <div class="setting_icon" @click="setSettingShow(true)">
        <img src="/assets/v/setting.png" alt="设置">
      </div>
    </div> -->
    <div class="title" />
  </div>
</template>

<style scoped lang="scss">
.title_wrap {
  height: 60px;
  background-image: url('/assets/v/top.png');
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-bottom: 4px;

  .guang {
    position: absolute;
    bottom: -26px;
    background-image: url('/assets/v/guang.png');
    background-position: 80px center;
    width: 100%;
    height: 56px;
  }

  .zuojuxing,
  .youjuxing {
    position: absolute;
    top: -2px;
    width: 140px;
    height: 6px;
    background-image: url('/assets/v/juxing1.png');
  }

  .zuojuxing {
    left: 11%;
  }

  .youjuxing {
    right: 11%;
    transform: rotate(180deg);
  }

  .timers {
    position: absolute;
    right: 0;
    top: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;

    .setting_icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin-left: 12px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.title {
  position: relative;
  // width: 500px;
  text-align: center;
  background-size: cover;
  color: transparent;
  min-height: 90px;
  max-height: 90px;
  line-height: 46px;
  background-image: url('/assets/img/header.png');
  background-size: cover;
  background-position: center center;

  .title-text {
    font-size: 38px;
    font-weight: 900;
    letter-spacing: 6px;
    width: 100%;
    background: linear-gradient(92deg, #0072ff 0%, #00eaff 48.8525390625%, #01aaff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
